import router from "@/router";
import { read, utils } from "xlsx";
import type { SelectItem, UserToken } from "@/definitions/types";
import axios from "axios";
import { API_HOST } from "@/constants/envs";
import { useUserStore } from "@/stores/user";
import { useAlertStore } from "@/stores/alert";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { deleteApi } from "@/utils/apis";

export async function routerPush(path: string): Promise<void> {
  if (router.currentRoute.fullPath !== path) {
    await router.push(path);
  }
}
export async function routerReplace(path: string): Promise<void> {
  if (router.currentRoute.fullPath !== path) {
    await router.replace(path);
  }
}

export function getSelectionByObject(object): SelectItem[] {
  return Object.keys(object).map((key) => ({
    text: object[key],
    value: key,
  }));
}

export function excelImport(file: File): Promise<unknown[]> {
  if (
    !file ||
    file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase() !== "xlsx"
  ) {
    return Promise.reject("잘못된 형식의 파일입니다.");
  }
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const data = reader.result;
      const workBook = read(data, { type: "binary" });
      if (!workBook.SheetNames[0]) {
        reject("내용이 없습니다.");
      }
      const rows = utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]]);
      if (rows.length === 0) {
        reject("내용이 없습니다.");
      }
      resolve(rows);
    };
    reader.readAsBinaryString(file);
  });
}
export async function goSignInPage(): Promise<void> {
  const { clearUser } = useUserStore();
  clearUser();
  await routerReplace("/sign-in");
}

export async function getNewToken(): Promise<
  | {
      accessToken: string;
      refreshToken: string;
    }
  | undefined
> {
  try {
    const response = await axios
      .create({
        baseURL: API_HOST,
        headers: {
          contentType: "application/json",
          AuthorizationR: await getValidatedRefreshToken(),
        },
      })
      .get<UserToken>("api/v1/users/token/refresh");
    return response.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status || 500;
      if (statusCode === 401 || e.message === "Invalid token specified!") {
        await goSignInPage();
      } else if ([403, 404, 500].includes(statusCode)) {
        const { toastError } = useAlertStore();
        toastError(e.message);
      } else {
        const { toastError } = useAlertStore();
        console.warn(`Missing Status Code: ${statusCode}`);
        toastError(e.message);
      }
    } else {
      console.error(e);
    }
  }
}

export async function signOut(): Promise<void> {
  try {
    await deleteApi("v1/users/sign-out", false);
  } catch (e) {
    console.error(e);
  }
  await goSignInPage();
}

export async function getValidatedAccessToken(): Promise<string> {
  let accessToken = window.localStorage.getItem("accessToken");
  if (!accessToken) {
    await goSignInPage();
    return "";
  }

  try {
    if (
      dayjs((jwt_decode(accessToken) as { exp: number }).exp * 1000).isBefore(
        dayjs(),
      )
    ) {
      const { reIssueAccessToken } = useUserStore();
      await reIssueAccessToken();
      accessToken = window.localStorage.getItem("accessToken");
    }
  } catch (e: unknown) {
    await signOut();
  }
  return accessToken ?? "";
}

export async function getValidatedRefreshToken(): Promise<string> {
  const refreshToken = window.localStorage.getItem("refreshToken");
  if (
    !refreshToken ||
    dayjs((jwt_decode(refreshToken) as { exp: number }).exp * 1000).isBefore(
      dayjs(),
    )
  ) {
    await goSignInPage();
  }
  return refreshToken ?? "";
}

export function rememberIdAndPwd(loginId: string, password: string): void {
  localStorage.setItem("remember", "yes");
  localStorage.setItem("rememberId", loginId);
  localStorage.setItem("rememberPwd", password);
}

export function clearRememberedIdAndPwd(): void {
  localStorage.removeItem("remember");
  localStorage.removeItem("rememberId");
  localStorage.removeItem("rememberPwd");
}
