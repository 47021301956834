import type { SelectItem } from "@/definitions/types";

export const MenuType = {
  GROUP: "GROUP",
  PAGE: "PAGE",
  NEW_TAB: "NEW_TAB",
} as const;
export type MenuTypesString = (typeof MenuType)[keyof typeof MenuType];

const COMMON_DATA_TYPE = {
  all: "전체자료",
  electronicSubmission: "전자제출",
  inspection: "검사",
  error: "오류",
  repair: "수리",
  request: "의뢰",
  correction: "보완",
  temp1: "담변",
} as const;

export const inboundDataType = {
  ...COMMON_DATA_TYPE,
  incompleteData: "미결자료",
  pending: "미결",
  submission: "제출",
  receipt: "접수",
  approval: "결재",
  embroidery: "자수",
  waiting: "대기",
  transmissionCargo: "송신화물",
  transmissionStorage: "송신저장",
  transmissionCalculation: "송신계약",
  transmissionOverseas: "송신해외",
  residentError: "주민오류",
  notInspected: "미검",
  quarantine: "검역",
  tax: "세금",
  name: "이름",
  moving: "이사",
  phoneNumber: "전번",
  revocation: "취하",
  seoStatement: "서변",
  service: "서보",
  internalInspection: "동검",
  review: "검토",
  residentNonDelivery: "주민미통판",
};

export const outboundDataType = {
  ...COMMON_DATA_TYPE,
  temp2: "서류제출",
  temp3: "선별",
  temp4: "송신삼자",
  temp5: "송신위약",
  temp6: "송신확인",
  temp7: "송신PD%",
};

export const inboundDateFilterType = {
  reportDate: "신고일자",
  acceptDate: "수리일자",
  updatedAt: "수정일자",
  unprocessedCases: "미처리건",
  notInUse: "사용안함",
};
export const outboundDateFilterType = {
  reportDate: "신고일자",
  acceptDate: "수리일자",
  loadingDeadlineDate: "적재기한",
  updatedAt: "수정일자",
  testDesiredDate: "검사희망일",
  freightBondedTransportStart: "보세운송 시작",
  freightBondedTransportEnd: "보세운송 종료",
  expectedDepartureDate: "출항일자",
  temp1: "미처리건",
  noUse: "사용안함",
};

export const inboundFieldType = {
  all: "모든 검색",
  requestNumber: "의뢰번호",
  invoice: "인보이스",
  reportNumber: "신고번호",
  houseNumber: "BL번호",
  fileNumber: "파일번호",
  masterNumber: "Master BL번호",
  paymentNoticeNumber: "납부고지번호",
  taxpayerBusinessNumber: "납세자사업자번호",
  tradePartnerCustomsCode: "무역거래처번호",
  overseasCustomerCustomsCode: "해외공급자번호",
  consigneeName: "수입자상호",
  taxpayerName: "납세자상호",
  itemCustomCode: "세번부호",
  productName: "표준품명",
  productTradingName: "거래품명",
  trademarkName: "상표명",
  modelSpecification: "규격품명",
  ingredient: "규격성분",
  customsDeclarationTradeType: "거래구분",
  collectionType: "징수형태",
  customsDeclarationCode: "신고세관",
  arrivalTestPlaceCode: "장치장부호",
  taxpayerAgencyCode: "대행사코드",
  inspection: "검사",
  incotermsCode: "인도조건",
  userMemo: "사용자메모",
  createdBy: "입력자",
};
export const outboundFieldType = {
  all: "모든 검색",
  reportNumber: "제출번호",
  houseNumber: "B/L번호",
  requestNumber: "의뢰번호",
  itemCustomCode: "세번부호",
  productName: "표준품명",
  tradeProductName: "거래품명",
  invoice: "송품장부호",
  productCode: "규격품명",
  temp: "전체규격품명",
  ingredient: "규격성분",
  declarationNumber: "신고번호",
  freightNumber: "컨테이너번호",
  manufacturer: "제조자",
  consignorName: "수출자",
  consignorBusinessNumber: "수출자사업번호",
  overseasCustomerName: "해외거래처",
  acceptNumber: "수리번호",
  customsClearanceTrade: "거래구분",
  customsDeclarationCode: "신고세관",
  transportationCountryCode: "목적국",
  productState: "상태값",
  productAddress: "물품소재지",
};

export const reportDepartmentType = {
  ALL: "All",
  MY: "My",
  AREA: "Area",
} as const;

export const FormStatusType = {
  NEW: "NEW",
  UPDATE: "UPDATE",
  VIEW: "VIEW",
} as const;

export type FormStatusTypeString =
  (typeof FormStatusType)[keyof typeof FormStatusType];

export const OutboundTabType = {
  OUTBOUNDS: "OUTBOUNDS",
  FORM: "FORM",
  ITEMS: "ITEMS",
  STANDARDS: "STANDARDS",
} as const;
export type OutboundTabTypeString =
  (typeof OutboundTabType)[keyof typeof OutboundTabType];
export const InboundTabType = {
  INBOUNDS: "INBOUNDS",
  FORM: "FORM",
  ITEMS: "ITEMS",
  STANDARDS: "STANDARDS",
} as const;
export type InboundTabTypeString =
  (typeof InboundTabType)[keyof typeof InboundTabType];

export const CodeType = {
  CUSTOMS_DECLARATION_CODE: "세관 및 출장소",
  CUSTOMS_DECLARATION_PLAN: "통관계확",
  CUSTOMS_DECLARATION_CLASS_CODE: "과 부호",
  CONSIGNOR_TYPE: "수출자 구분",
  CONSIGNOR_AGENCY: "수출 대행자",
  CONSIGNOR_SHIPPER_CODE: "수출 화주",
  PAYMENT_CURRENCY: "통화 코드",
  CUSTOMS_CLEARANCE_DECLARATION_TYPE: "신고 구분",
  CUSTOMS_CLEARANCE_PAYMENT_TYPE: "결제 방법",
  TRANSPORTATION_CODE: "운송 형태",
  TRANSPORTATION_TYPE: "운송 용기",
  TRANSPORTATION_COUNTRY_CODE: "적출국",
  TOTAL_PACKAGE_UNIT: "수량 단위",
  REFUND_APPLICANT: "환급신청인",
  MANUFACTURER_INDUSTRIAL_COMPLEX_CODE: "산업단지 부호",
  SIMPLY_REFUND: "간이환급신청구분",
  TEMPORARY_OPENING_YN: "사전임시개청",
  CUSTOMS_CLEARANCE_TRADE_TYPE: "수출거래 구분",
  CUSTOMS_CLEARANCE_EXPORT_TYPE: "수출거래 종류",
  TRANSPORTATION_CARRY_IN_PLACE: "반입지역",
  PRODUCT_STATE: "물품 상태",
  CONTAINER_REGISTRATION: "컨테이너 적입",
  FREIGHT_NUMBER_SEND: "화물번호전송구분",
  CONSIGNEE_TYPE: "수입자 구분",
  CUSTOMS_DECLARATION_TRADE_TYPE: "수입거래 구분",
  CUSTOMS_DECLARATION_INBOUND_TYPE: "수입종류 구분",
  CUSTOMS_DECLARATION_USE_REPORT: "사용신고 구분",
  CUSTOMS_DECLARATION_PAYMENT_TYPE: "결제방법",
  COLLECTION_TYPE: "징수형태",
  COLLECTION_EXPRESS_TRANSPORTATION_CODE: "특송업체",
  COLLECTION_INTER_KOREAN_TRADE: "남북교역",
  FREIGHT_BL_SPLIT_YN: "분할",
  FREIGHT_BL_SPLIT_REASON: "사유",
  POD: "항구공항 코드",
  ORIGIN_CERTIFICATE_YN: "원산지증명서 유무",
  INCOTERMS_CODE: "인도조건",
  ADD_CODE: "가산공제구분 부호",
  TAX_UNDECLARED_CODE: "미신고과구분 부호",
  TARIFF_PERCENT: "관세율 구분",
  TARIFF_FLEXIBLE_PERCENT: "탄력관세율 구분",
  INDIVIDUAL_CONSUMPTION_TAX_EXEMPTION_CODE: "개별소비세 면세부호",
  LIQUOR_TAX_EXEMPTION_CODE: "주세 면세부호",
  TRAFFIC_TAX_EXEMPTION_CODE: "교통세 면세부호",
  ROYALTY_TYPE: "로열티 구분 부호",
  EDUCATION_TYPE: "교육세 과세 구분",
  AGRICULTURAL_SPECIAL_TYPE: "농특세 과세 구분",
  VAT_TYPE: "부가세 과세 구분",
  VAT_REDUCTION_CUSTOMS_CODE: "부가세 감면 부호",
  TARIFF_TYPE: "관세감면구분",
  TOTAL_WEIGHT_UNIT: "중량 단위",
  STANDARD_PACKAGE_UNIT: "규격수량단위",
  ORIGIN_DECISION_TYPE: "원산지결정기준",
  ORIGIN_PRESENCE_TYPE: "원산지표시유무",
  ORIGIN_METHOD_TYPE: "원산지표시방법",
  ORIGIN_ISSUE_AREA: "원산지발급지역CN",
  IMPORT_ORIGIN_STANDARD: "수입원산지기준",
  IMPORT_ORIGIN_SPLIT_YN: "수입원산지분할 부호",
  INSPECTION_CODE: "검사방법 부호",
  INSPECTION_REQUIRED: "검사필요여부",
  EXPRESS_DELIVERY_COMPANY_TYPE: "특송업체C/S부호",
} as const;

export const YesOrNo = {
  Y: "Y",
  N: "N",
} as const;

export type YesOrNoType = (typeof YesOrNo)[keyof typeof YesOrNo];

export const IncotermsCode = {
  EXW: "EXW",
  FAS: "FAS",
  FCA: "FCA",
  FOB: "FOB",
  CFR: "CFR",
  CIF: "CIF",
  CPT: "CPT",
  CIP: "CIP",
  DAT: "DAT",
  DAP: "DAP",
  DDP: "DDP",
} as const;

export type IncotermsCodeType =
  (typeof IncotermsCode)[keyof typeof IncotermsCode];

export const Department = {
  MY: "MY",
  AREA: "AREA",
  DEPARTMENT: "DEPARTMENT",
} as const;

export type DepartmentType = (typeof Department)[keyof typeof Department];

export const DEPARTMENT: SelectItem<DepartmentType>[] = [
  { value: Department.MY, text: "My" },
  { value: Department.AREA, text: "Area" },
  { value: Department.DEPARTMENT, text: "부서" },
];
