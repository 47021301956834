import Vue from "vue";
import Router from "vue-router";
import type { Route } from "vue-router";
import type { NavigationGuardNext, RouteConfig } from "vue-router/types/router";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import store from "@/stores";
import { goSignInPage } from "@/utils/commands";

Vue.use(Router);

const requireAuth =
  () => async (_to: Route, _from: Route, next: NavigationGuardNext) => {
    const { loggedIn } = storeToRefs(useUserStore(store));
    if (loggedIn.value) {
      return next();
    }
    return goSignInPage();
  };

const routes = (): RouteConfig[] => {
  const management: RouteConfig[] = [
    {
      path: "/inbound",
      beforeEnter: requireAuth(),
      component: () => import("@/views/management/inbound/InboundPage.vue"),
    },
    {
      path: "/special-page",
      beforeEnter: requireAuth(),
      component: () => import("@/views/special-page/SpecialPagePage.vue"),
    },
    {
      path: "/",
      beforeEnter: requireAuth(),
      component: () => import("@/views/management/outbound/OutboundPage.vue"),
    },
  ];
  return [
    ...management,
    {
      path: "/sign-in",
      component: () => import("@/views/sign/SignIn.vue"),
      meta: {
        layout: "auth",
      },
    },
  ];
};

export default new Router({
  mode: "history",
  base: import.meta.env.BASE_URL,
  scrollBehavior(_to: Route, _from: Route, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
  routes: routes(),
});
