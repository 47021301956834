import Vue from "vue";
import Vuetify from "vuetify/lib";
import ko from "vuetify/src/locale/ko";
import IcGnbExportIcon from "@/custom-icon/IcGnbExportIcon.vue";
import IcGnbImportIcon from "@/custom-icon/IcGnbImportIcon.vue";
import IcGnbCodeIcon from "@/custom-icon/IcGnbCodeIcon.vue";
import RadioIcon from "@/custom-icon/RadioIcon.vue";
import RadioCheckedIcon from "@/custom-icon/RadioCheckedIcon.vue";
import DetailsInfoIcon from "@/custom-icon/DetailsInfoIcon.vue";
import StandardInfoIcon from "@/custom-icon/StandardInfoIcon.vue";
import StandardIcon from "@/custom-icon/StandardIcon.vue";
import RanInfoIcon from "@/custom-icon/RanInfoIcon.vue";
import RanInfoIcon2 from "@/custom-icon/RanInfoIcon2.vue";
import RanInfoIcon3 from "@/custom-icon/RanInfoIcon3.vue";
import DocumentIcon from "@/custom-icon/DocumentIcon.vue";
import DetailsInfoIcon2 from "@/custom-icon/DetailsInfoIcon2.vue";

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      DocumentIcon: {
        component: DocumentIcon,
      },
      RanInfoIcon: {
        component: RanInfoIcon,
      },
      RanInfoIcon2: {
        component: RanInfoIcon2,
      },
      RanInfoIcon3: {
        component: RanInfoIcon3,
      },
      IcGnbExportIcon: {
        component: IcGnbExportIcon,
      },
      IcGnbImportIcon: {
        component: IcGnbImportIcon,
      },
      IcGnbCodeIcon: {
        component: IcGnbCodeIcon,
      },
      RadioIcon: {
        component: RadioIcon,
      },
      RadioCheckedIcon: {
        component: RadioCheckedIcon,
      },
      DetailsInfoIcon: {
        component: DetailsInfoIcon,
      },
      DetailsInfoIcon2: {
        component: DetailsInfoIcon2,
      },
      StandardInfoIcon: {
        component: StandardInfoIcon,
      },
      StandardIcon: {
        component: StandardIcon,
      },
    },
  },
  lang: {
    locales: { ko },
    current: "ko",
  },
  theme: {
    themes: {
      light: {
        primary: "#FDD835",
        secondary: "#4388FF",
        success: "#4caf50",
        error: "#ff5252",
        info: "#2196f3",
        warning: "#fb8c00",
      },
    },
  },
});
