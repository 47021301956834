export const API_HOST: string = import.meta.env.VITE_BASE_API_URL;
export const PRODUCT_TITLE: string = import.meta.env.VITE_PRODUCT_TITLE;
export const PRODUCT_VERSION: string = import.meta.env.VITE_PRODUCT_VERSION;
export const ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT;
export const SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN;

export const DATE_FORMAT_STRING: string = import.meta.env
  .VITE_DATE_FORMAT_STRING;
export const DATETIME_MINUTE_FORMAT_STRING: string = import.meta.env
  .VITE_DATETIME_MINUTE_FORMAT_STRING;
export const LOCALE: string = import.meta.env.VITE_LOCALE;
export const FOOTER_PROPS_MAX_100 = {
  showFirstLastPage: true,
  itemsPerPageOptions: [10, 20, 50, 100],
};
